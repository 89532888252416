import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { firebase } from 'src/environments/environment';
import { AppComponent } from '../app.component';
import { NGPrimeModule } from '../modules/ngprime.module';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserAnimationsModule,
		BrowserModule,
		BrowserModule,
		FormsModule,
		FormsModule,
		HttpClientModule,
		NGPrimeModule,
		ReactiveFormsModule,
		provideAuth(() => getAuth()),
		provideFirebaseApp(() => initializeApp(firebase.Cfg)),
		provideFirestore(() => getFirestore()),
		provideFunctions(() => getFunctions()),
		provideMessaging(() => getMessaging()),
		provideStorage(() => getStorage()),
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
